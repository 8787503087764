import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import AboutUs from './aboutdepartment';
import Teachers from './teachers'
import Dheader from './Dheader';
import ProgramOutcomes from './ProgramOutcomes';
import TeachingPlan from './TeachingPlan';
import Syllabus from '../../Pages/Syllabus';
import Dactivity from './dactivity';
import { Button } from 'react-bootstrap';
import Question from './Question';
import Routine from './routine';

import ValueAddedCourse from './ValueAddedCourse';

const VerticalTabs = ({ data }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { title } = useParams();
  const contentRef = useRef(null);

  
  const handleTabClick = (index) => {
    setActiveTab(index);
    setIsMenuOpen(false);
  };

  const filteredTabContents = data.find((tab) => tab.department === title);

  const tabContents = [
    {
      title: 'About the Department',
      content: (
        <div>
          <AboutUs data={filteredTabContents?.aboutus}/>
        </div>
      )
    },
    {
      title: 'Our Teachers',
      content: (
        <div>
          <Teachers cardData={filteredTabContents?.teachers}/>
        </div>
      )
    },
    {
      title: 'Outcome',
      content: (
        <div>
          <ProgramOutcomes programOutcomes={filteredTabContents?.programOutcomes}/>
        </div>
      )
    },
    {
      title: 'E-Material',
      content: (
        <div>
        <br></br>
        <br></br>
          <Question dept={title}/>
        </div>
      )
    },
    {
      title: 'Value Added Course',
      content: (
        <div>
        <br></br>
        <br></br>
       {/* <ValueAddedCourse data={filteredTabContents?.valeadded}/> */}
        
        </div>
      )
    },
    {
      title: 'Activities',
      content: (
        <div>
        <br></br>
          
         <Dactivity dept={title}/>
        </div>
      )
    },
    {
      title: 'Teaching/Lesson Plan',
      content: (
        <div>
          <br></br>
          <TeachingPlan dept = {title}/>
        </div>
      )
    },
    {
      title: 'Syllabus',
      content: (
        <div>
          <br></br>
          <Syllabus dept = {title}/>
        </div>
      )
    },
    {
      title: 'Departmental Routine',
      content: (
        <div>
        <h3>Departmental Routine  </h3><br></br>
         
          <Routine dept = {title}/>
        </div>
      )
    },
    {
      title: 'Awards',
      content: (
        <div>
        <br></br>
        <br></br>
         
         <Link to ='/awards'> <Button > Click Here to see Awards </Button></Link>
          
        </div>
      )
    },
    {
      title: 'Ethics',
      content: (
        <div>
          <h2>Ethics</h2>
          <p>Content for the Ethics tab goes here.</p>
        </div>
      )
    }
  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const updateScreenSize = () => {
    setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    updateScreenSize();
    window.addEventListener('resize', updateScreenSize);
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return (
    <>
    <div><Dheader data={filteredTabContents?.department}/></div>
      <div className={`vertical-tabs ${isMobile ? 'mobile-view' : ''}`}>
      {isMobile && (
        <>
          <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          {isMenuOpen && (
            <div className="menu-dropdown">
              {tabContents.map((tab, index) => (
                <div
                  key={index}
                  className={`tab ${index === activeTab ? 'mobactive' : ''}`}
                  onClick={() => handleTabClick(index)}
                >
                  {tab.title}
                </div>
              ))}
            </div>
          )}
        </>
      )}
     {!isMobile && (
        <div className="tab-list colorful-pattern">
          {tabContents.map((tab, index) => (
            <div
              key={index}
              className={`tab ${index === activeTab ? 'active' : ''}`}
              onClick={() => handleTabClick(index)}
            >
              {tab.title}
            </div>
          ))}
        </div>
      )}
      <div className="tab-content" ref={contentRef}>
        {tabContents[activeTab].content}
      </div>
    </div>
    </>
  );
};

export default VerticalTabs;