import React from 'react'
import AlumniComponent from '../components/AlumniComponent'
import CarouselComponent from '../components/CarouselComponent';
import image1 from '../Image/gb1.jpg'
import image2 from '../Image/gb1.jpg'
import image3 from '../Image/gb1.jpg'
import NoticeBoard from '../sections/NoticesSection';
import Notice from '../resources/alumninotice.json'
import Description from '../components/Description';
import {Row,Col,Button} from 'react-bootstrap'
import TableComponent from '../components/TableComponent';
import ImageSlider from '../components/portfolioslider';



const Alumni = () => {
    const data = 'Calcutta Girls’ College has established itself as an institution of higher learning that encourages young women towards academic betterment, self-reliance, and gaining awareness of a meaningful role in society.The college offers its students modernized academic and infrastructural facilities, individual attention, provisions for various co-curricular activities, programs for career guidance and placements'
    const temp = [
        {  name: 'Mrs.Gulshan Khan', designation: 'SACT' },
        {  name: 'Miss Zarqua ', designation: 'Member' },
        {  name: 'Dr.Indranil Kar', designation: 'Govt. Nominee' },
        {  name: 'Dr. Dabir Ahmed', designation: 'Nominee Of W.B. State Council Of Higher Education ' },
        {  name: 'Mr. Sandipan Saha ', designation: 'Govt. Nominee' },
      ];

      
    const alumniImages=[image1,image2,image3];
    const EventData = [
      { id: 1, date: "2023-24", heading: 'Alumni Report', profileurl: 'https://static.muoninfotech.com/./assets/IQAC/2023-2024 Alumni report (1).pdf' },
    ];
    
  return (
    <>
    <div><CarouselComponent data={alumniImages}/></div>
    <div className='conatinershadow p-3 mb-5 bg-white rounded'>
      <br></br>
    <h2>Our Alumni</h2>
    <Description data={data}/></div>

    <div className='container shadow p-3 mb-5 bg-white rounded'>
      <br></br>
        <div ><Button variant='primary' className='btn btn-primary btn-sm' style={{marginLeft: '70rem'}}>Alumni Registration Form</Button></div><br></br>
        <Row  >
            <Col className='container-sm'>
            <h4>Our successful Alumni list</h4>
            <br></br>
            <ImageSlider />
            <br></br>
            <Button>Alumni List</Button>
            
            </Col>
            <Col className='container-sm'>
            <h4>Alumni Committee</h4>
            <TableComponent tabledata={temp} />
            
            </Col>
            
        </Row>
    </div>
    <br></br>
    <div className="container">
      {EventData.map((item) => (
        <div key={item.id} className="card my-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-3">
                <p>Year : {item.date}</p>
              </div>
              <div className="col-md-6">
                <h3>{item.heading}</h3>
              </div>
              <div className="col-md-3">
                <button className="btn btn-primary" type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = item.profileurl;
                  }}>Download</button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
    </>
  )
}

export default Alumni